import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4B4EA4",
        primaryLight: "#676AB1",
        secondary: "#B63717",
        accent: "#C35A3C",
        white: "#F5F5F5",
      }
    },
  },
});
