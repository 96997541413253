<template>
    <v-container fluid fill-height style="background-color: #F5F5F5; height: 100vh;">
        <v-row class="px-4 px-lg-16" align="center" justify="center">
            <v-col align-self="center" justify="center">
                <h1 class="primary--text" style="text-align: center;">Disfrutando ahorrando</h1>

                <h3 class="mt-2 mx-0 mx-lg-16 px-0 px-lg-16" style="text-align: center; color: #28293E;">
                    Somos una plataforma 100% mexicana que busca incrementar la frecuencia de consumo en restaurantes,
                    bares y cafeterías. Abriendo mesas con descuentos atractivos para los miembros de EatPass.
                </h3>

                <h4 class="secondary--text mt-8" style="text-align: center;">Esperanos muy pronto en AppStore y PlayStore</h4>

                <v-row justify="center" align="center">
                    <v-col cols="5" lg="2">
                        <a
                            href="https://apps.apple.com/mx/app/kipass/id6450484786"
                            target="_blank"
                        >
                            <v-img
                                alt="Eat Pass"
                                src="@/assets/stores/appstore.png"
                                height="10vh"
                                contain
                            ></v-img>
                        </a>
                    </v-col>
                    <v-col cols="5" lg="2">
                        <a
                            href="https://apps.apple.com/mx/app/kipass/id6450484786"
                            target="_blank"
                        >
                            <v-img
                                alt="Eat Pass"
                                src="@/assets/stores/playstore.png"
                                height="12.5vh"
                                contain
                            ></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "DescriptionSection"
}
</script>

<style scoped>

</style>