<template>
    <v-container fluid fill-height style="background-color: #4B4EA4; height: 100vh;">
        <v-row class="px-16" align="center" justify="center">
            <v-col align-self="center" justify="center">
                <v-img
                        alt="KiPass"
                        src="@/assets/logo_red_white.png"
                        height="30vh"
                        contain
                ></v-img>

                <h1 class="white--text" style="text-align: center;">PRÓXIMAMENTE</h1>

                <h4 class="white--text" style="text-align: center;">2024</h4>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "ComingSoonJumbotron"
}
</script>

<style scoped>

</style>