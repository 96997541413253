<template>
    <div>
        <v-container fluid fill-height style="background-color: #4B4EA4; height: 100vh;">
            <v-row class="px-16" align="center" justify="center">
                <v-col align-self="center" justify="center">
                    <h1 class="white--text" style="text-align: center;">¿NECESITAS AYUDA?</h1>

                    <h4 class="white--text" style="text-align: center;">contacto@kipass.com.mx</h4>

                    <v-row align="center" justify="center">
                        <a href="mailto:contacto@kipass.com.mx" target="_blank">
                            <v-btn dark xl color="secondary" class="mt-16">
                                Contactar ahora
                            </v-btn>
                        </a>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  name: "SupportView"
}
</script>

<style scoped>

</style>