<template>
    <v-container fluid style="background-color: #B63717;">
        <v-row justify="center" align="center">
            <p class="white--text my-4" style="text-align: center;">
                <b>KiPass es una empresa de EATPASS S.A. DE C.V.</b>
                <br>México, 2024.
            </p>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "CustomFooter"
}
</script>

<style scoped>

</style>