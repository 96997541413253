<template>
    <div>
        <ComingSoonJumbotron></ComingSoonJumbotron>
        <DescriptionSection></DescriptionSection>
        <CustomFooter></CustomFooter>
    </div>
</template>

<script>
import ComingSoonJumbotron from "@/components/home/coming_soon/ComingSoonJumbotron.vue";
import DescriptionSection from "@/components/home/coming_soon/DescriptionSection.vue";
import CustomFooter from "@/components/footer/Footer.vue";

export default {
  name: "HomeView",
  components: {CustomFooter, DescriptionSection, ComingSoonJumbotron,},
}
</script>

<style scoped>

</style>